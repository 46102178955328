import React, { Component } from 'react'
import { Link } from 'gatsby'
import Paginator from 'react-js-pagination'

import { RequestGetter } from '../../helpers/request'
import { StyledRow, BlogInfoWrapper, StyledImg } from '../StylesPages/blogStyle'

interface Blog {
  id: number
  autor: string
  content: string
  updated_at: string
  image_url: string
  order: number
  short_description: string
  title: string
  slug: string
  small_title: string
}

class BlogList extends Component {
  state = {
    blogs: [],
    activePage: 1,
    itemsPerPage: Number(process.env.ITEMS_PER_PAGE) || 6,
    totalItems: 0
  }

  componentDidMount() {
    this.getBlogs(this.state.activePage)
  }

  getBlogs = (pageNumber: number) => {
    RequestGetter('get', 'getblogs', null, {
      limit: this.state.itemsPerPage,
      offset: (pageNumber - 1) * this.state.itemsPerPage
    }).then((data: any) => {
      this.setState({ blogs: data.data.blogs, totalItems: data.data.rows, activePage: pageNumber })
    })
  }

  getMonthIni = (month: string) => {
    switch (month) {
      case '01':
        return 'ENE'
        break
      case '02':
        return 'FEB'
        break
      case '03':
        return 'MAR'
        break
      case '04':
        return 'ABR'
        break
      case '05':
        return 'MAY'
        break
      case '06':
        return 'JUN'
        break
      case '07':
        return 'JUL'
        break
      case '08':
        return 'AGO'
        break
      case '09':
        return 'SEP'
        break
      case '10':
        return 'OCT'
        break
      case '11':
        return 'NOV'
        break
      case '12':
        return 'DIC'
        break
    }
  }

  render() {
    const blogs = this.state.blogs.map((blog: Blog, index: number) => {
      let truncatedDate = blog.updated_at.substr(0, 10)
      const splittedDate = truncatedDate.split('-')
      const month = this.getMonthIni(splittedDate[1])
      truncatedDate = month + ' - ' + splittedDate[2] + ' - ' + splittedDate[0]

      if (index % 2 == 0) {
        return (
          <StyledRow key={blog.id} className="row">
            <div className="col-md-6 offset-md-0 col-lg-4 offset-lg-2 text-center">
              <StyledImg className="img-responsive" src={blog.image_url} />
            </div>
            <BlogInfoWrapper className="col-md-6 col-lg-4">
              <p className="date">{truncatedDate}</p>
              <h2 className="title">{blog.title}</h2>
              <h3 className="small_title">{blog.small_title}</h3>
              <p className="autor">Por {blog.autor}</p>
              <p className="description">{blog.short_description}</p>
              <p className="text-right link">
                <Link className="see_more text-right" to={`/article/${blog.slug}`}>
                  Leer más
                </Link>
              </p>
            </BlogInfoWrapper>
          </StyledRow>
        )
      } else {
        return (
          <StyledRow key={blog.id} className="row">
            <BlogInfoWrapper className="col-md-6 offset-md-0 col-lg-4 offset-lg-2 text-center">
              <p className="date">{truncatedDate}</p>
              <h2 className="title">{blog.title}</h2>
              <h3 className="small_title">{blog.small_title}</h3>
              <p className="autor">Por {blog.autor}</p>
              <p className="description">{blog.short_description}</p>
              <p className="text-right link">
                <Link className="see_more text-right" to={`/article/${blog.slug}`}>
                  Leer más
                </Link>
              </p>
            </BlogInfoWrapper>
            <div className="col-md-6 col-lg-4">
              <StyledImg className="img-responsive" src={blog.image_url} />
            </div>
          </StyledRow>
        )
      }
    })
    return (
      <div className="container">
        {blogs}
        {this.state.totalItems > this.state.itemsPerPage && (
          <Paginator
            totalItemsCount={this.state.totalItems}
            onChange={this.getBlogs}
            itemsCountPerPage={this.state.itemsPerPage}
            pageRangeDisplayed={3}
            activePage={this.state.activePage}
          />
        )}
      </div>
    )
  }
}

export default BlogList
