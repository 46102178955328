import React from 'react'
import Layout from '../components/Layout'
import BlogList from '../components/BlogList'
const Logo = require('../assets/images/general/logo-gt.svg')
import { ImgContainer } from '../components/StylesPages/blogStyle'

import '../styles/pagination.css'

export default () => (
  <Layout themeType="dark" currentPage={'blog'}>
    <div className="container">
      <div className="row">
        <ImgContainer className="col-sm-4 offset-sm-4">
          <img src={Logo} />
          <br />
        </ImgContainer>
      </div>
    </div>
    <BlogList />
  </Layout>
)
