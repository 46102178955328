import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'
import LazyImage from '../LazyImage'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 0;
  ${media.md`  
    padding-top: 1rem;
  `};
`

export const StyledRow = styled.div`
  margin: 25px 0;
`

export const StyledRowRelated = styled.div`
  cursor: pointer;
  margin: 0px 0;
  padding-top: 10px;
  border-top: solid gray 1px;

  &.first {
    border-top: none;
  }
`

export const StyledCol = styled.div`
  height: auto;
  overflow: auto;
  text-align: justify;
  p.description {
    ${media.lg`  
      height: 75px;
      overflow: hidden;
      line-height: 18px;
    `};
  }

  ${media.md`  
  padding-left: 0;
  padding-right: 10px;
  `};
`

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 3.7px;
  color: ${theme.purpleBrown};
  margin-top: 26px;
`

export const RelatedTitle = styled.h3`
  text-transform: uppercase;
`

export const BlogInfoWrapper = styled.div`
  color: #333;
  p.date {
    text-transform: uppercase;
    color: grey;
    margin-bottom: 10px;
  }

  h2.title {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 18px;
  }

  h3.small_title {
    color: #000;
    font-size: 16px;
    margin-bottom: 0;
  }

  p.autor {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 11px;
  }

  p.description {
    color: #000;
    text-align: justify;
    font-size: 13px;
  }

  p.link {
    text-align: right;
    margin-bottom: 0;

    a {
      color: #000000;
      font-size: 12px;
      text-decoration: underline;
    }
  }
`

export const StyledImg = styled.img`
  height: auto;
  width: 100%;
`

export const RelatedImg = styled.img`
  height: auto;
  width: 100%;
`

export const ImgContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  img {
    width: 60%;
  }

  ${media.sm`
    img{
      width: 100%;
    }   
  `};
`

export const Image = styled(LazyImage)`
  max-width: 80%;
`

export const Text = styled.p`
  color: ${theme.purpleBrown};
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 40px;
`

export const CollapseListContainer = styled.div`
  background: ${theme.lightGray};
  margin-bottom: 40px;
`

export const ShowMore = styled.div`
  border-top: solid gray 1px;
  text-align: center;
  padding-top: 15px;
  margin: 0 0 15px 0;

  a {
    color: black;
    text-decoration: underline;
  }
`
